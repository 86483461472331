import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Account from "../pages/account";
import CategoryEdit from "../pages/category/Edit";
import ConfigEdit from "../pages/configurator/Edit"
import Members from "../pages/member";
import MemberCreate from "../pages/member/Create";
import MemberEdit from "../pages/member/Edit";
import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";
import CalcHistory from "../pages/calcHistory/index";
import MemberTasks from "../pages/member/Tasks";
import UserCreate from "../pages/user/Create";
import EditMedia from "../pages/media/Edit";

const AdminRouter = () => {
  const role = useSelector((state) => state.auth.user.role);

  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />
        <Route path="calcHistory" element={<CalcHistory />} />


        <Route path="users" element={<Users />} />
        <Route path="user/:userId" element={<UserEdit />} />
        <Route path="users/create" element={<UserCreate />} />

        <Route path="members" element={<Members />} />
        <Route path="members/create" element={<MemberCreate />} />
        <Route path="member/:memberId" element={<MemberEdit />} />
        <Route path="member/:memberId/tasks" element={<MemberTasks />} />

        <Route path="catalog" element={<CategoryEdit />} />
        <Route path="media" element={<EditMedia />} />
        <Route path="configurator" element={<ConfigEdit />} />

      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
