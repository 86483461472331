import React from 'react';

function Door({ x, y, width, height, handlePosition, numHandles }) {
  const handleRadius = 5; // Радиус ручки

  const handles = Array.from({ length: numHandles }, (_, i) => {
    let cx;
    if (handlePosition === 'center') cx = width / 2;
    else if (handlePosition === 'left') cx = handleRadius * 2;
    else cx = width - handleRadius * 2;
    return <circle key={i} cx={cx} cy={height / 2 - handleRadius * 2} r={handleRadius} fill="black" />;
  });

  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect width={width} height={height} fill="lightgray" stroke="black" strokeWidth="2" />
      {handles}
    </g>
  );
}

export default Door;
