const roles = [
  {
    value: 1,
    title: "Главный администратор",
    desc: "",
    color: "#ff5630",
  },
  {
    value: 2,
    title: "Администратор",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 3,
    title: "Бухгалтер",
    desc: "",
    color: "#3366ff",
  },
  {
    value: 4,
    title: "Контент-менеджер",
    desc: "",
    color: "#1d9330",
  },
  {
    value: 5,
    title: "Сотрудник по проблемам с платежной системой и выводом средств",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 6,
    title: "Сотрудник по работе с подтверждением сделок",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 7,
    title: "Сотрудник по снятию ограничений задержки на вывод средств",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 8,
    title: "Сотрудник по спорным ситуациям (арбитраж)",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 9,
    title: "Сотрудник по иным вопросам",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 10,
    title: "Сотрудник по работе с доступами учетных записей",
    desc: "",
    color: "#ff9833",
  },
  {
    value: 11,
    title: "Сотрудник по работе с стоп словами",
    desc: "",
    color: "#ff9833",
  },

];

const isRole = ({ role }) => {
  let roleInfo = roles.find((e) => e.value === role);
  if (!roleInfo) {
    return "Роль не найдена";
  }

  return { role: roleInfo };
};

export { roles, isRole };
