import React from 'react';
import Door from './Door';

function Partition({ params }) {
  const { partitionWidth, partitionHeight, numDoors, doorWidth, doorHeight, handlePosition, numHandles } = params;

  // Вычисление масштабного коэффициента
  const scaleX = partitionWidth / (numDoors * doorWidth);
  const scaleY = partitionHeight / doorHeight;
  const scale = Math.min(scaleX, scaleY); // Выбираем минимальный коэффициент для предотвращения вылета за пределы

  const scaledDoorWidth = doorWidth * scale;
  const scaledDoorHeight = doorHeight * scale;


  const doors = Array.from({ length: numDoors }, (_, i) => (
    <Door
      key={i}
      x={i * scaledDoorWidth}
      y={0}
      width={scaledDoorWidth}
      height={scaledDoorHeight}
      handlePosition={handlePosition}
      numHandles={numHandles}
    />
  ));

  return (
    <svg width={partitionWidth} height={partitionHeight} viewBox={`0 0 ${partitionWidth} ${partitionHeight}`}>
      {doors}
    </svg>
  );
}

export default Partition;
