import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCategories = async (data) => {
  const response = await $authApi.get(apiRoutes.CATEGORIES, {
    params: data,
  });

  return response?.data;
};

const getCategory = async (id) => {
  const response = await $authApi.get(apiRoutes.CATEGORY, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createCategory = async (data) => {
  const response = await $authApi.postForm(apiRoutes.CATEGORY_CREATE, data);
  return response?.data;
};

const editCategory = async (data) => {
  const response = await $authApi.postForm(apiRoutes.CATEGORY_EDIT, data);
  return response?.data;
};

const deleteCategory = async (ids) => {
  const response = await $authApi.delete(apiRoutes.CATEGORIES, {
    data: { ids },
  });
  return response?.data;
};

const createRegion = async (data) => {
  const response = await $authApi.post(apiRoutes.REGION, data);
  return response?.data;
};

const editRegion = async (data) => {
  const response = await $authApi.put(apiRoutes.REGION, data);
  return response?.data;
};

const editRegionStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.REGION_STATUS, data);
  return response?.data;
};

const deleteRegion = async (data) => {
  const response = await $authApi.delete(apiRoutes.REGION, {
    data,
  });
  return response?.data;
};


const createServer = async (data) => {
  const response = await $authApi.post(apiRoutes.SERVER, data);
  return response?.data;
};

const editServer = async (data) => {
  const response = await $authApi.put(apiRoutes.SERVER, data);
  return response?.data;
};

const editServerStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.SERVER_STATUS, data);
  return response?.data;
};

const deleteServer = async (data) => {
  const response = await $authApi.delete(apiRoutes.SERVER, {
    data,
  });
  return response?.data;
};

const createParam = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARAM, data);
  return response?.data;
};

const editParam = async (data) => {
  const response = await $authApi.putForm(apiRoutes.PARAM, data);
  return response?.data;
};

const editParamStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.PARAM_STATUS, data);
  return response?.data;
};

const deleteParam = async (data) => {
  const response = await $authApi.delete(apiRoutes.PARAM, {
    data,
  });
  return response?.data;
};


const createOption = async (data) => {
  const response = await $authApi.post(apiRoutes.OPTION, data);
  return response?.data;
};

const editOption = async (data) => {
  const response = await $authApi.put(apiRoutes.OPTION, data);
  return response?.data;
};

const editOptionStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.OPTION_STATUS, data);
  return response?.data;
};

const deleteOption = async (data) => {
  const response = await $authApi.delete(apiRoutes.OPTION, {
    data,
  });
  return response?.data;
};

export {
  getCategories,
  getCategory,
  createCategory,
  editCategory,
  deleteCategory,
  createRegion,
  editRegion,
  editRegionStatus,
  deleteRegion,
  createServer,
  editServer,
  editServerStatus,
  deleteServer,
  createParam,
  editParam,
  editParamStatus,
  deleteParam,
  createOption,
  editOption,
  editOptionStatus,
  deleteOption
};