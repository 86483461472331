import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Container,
} from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoAdd,
  IoAddOutline,
  IoChevronBackOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";

import Textarea from "../../components/UI/Textarea";

import CustomModal from "../../components/utils/CustomModal";
import { generateUrl } from "../../helpers/all";
import { getImageURL } from "../../helpers/image";
import {
  createOption,
  createParam,
  createRegion,
  createServer,
  deleteOption,
  deleteParam,
  deleteRegion,
  deleteServer,
  editCategory,
  editOption,
  editOptionStatus,
  editParam,
  editParamStatus,
  editRegion,
  editRegionStatus,
  editServer,
  editServerStatus,
  getCategories,
  getCategory,
} from "../../services/category";
import DragDropFile from "../../components/DragDropFile";
import ImageCropper from "../../components/Cropper";
import { options } from "../../helpers/options";

function createOptionFun(data, idProp = "id", parentProp = "parent") {
  const tree = {};

  data.forEach((node) => {
    const id = node[idProp];
    tree[id] = { ...node, children: [] };
  });

  const rootNodes = [];

  data.forEach((node) => {
    const parentId = node[parentProp];
    if (parentId !== null) {
      const parentNode = tree[parentId];
      if (parentNode) {
        parentNode.children.push(tree[node[idProp]]);
      } else {
        rootNodes.push(tree[node[idProp]]);
      }
    } else {
      rootNodes.push(tree[node[idProp]]);
    }
  });

  return rootNodes;
}

const EditCategory = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const [catalog, setCatalog] = useState({
    items: false,
    loading: false,
  });

  const [showDelete, setShowDelete] = useState({
    show: false,
    data: false,
    event: false,
  });

  const [showEditRegion, setShowEditRegion] = useState({
    show: false,
    data: false,
  });
  const [showCreateRegion, setShowCreateRegion] = useState({
    show: false,
    data: {
      title: "",
      priority: 0,
      status: true,
    },
  });
  const [showEditServer, setShowEditServer] = useState({
    show: false,
    data: false,
  });
  const [showCreateServer, setShowCreateServer] = useState({
    show: false,
    data: {
      title: "",
      priority: 0,
      status: true,
    },
  });

  const [showEditParam, setShowEditParam] = useState({
    show: false,
    data: false,
  });
  const [showCreateParam, setShowCreateParam] = useState({
    show: false,
    data: false,
  });

  const [editImageBanner, setEditImageBanner] = useState({
    show: false,
    data: false,
  });

  const [showCreateOption, setShowCreateOption] = useState({
    show: false,
    data: false,
  });

  // const [showEditOptionGeneral, setShowEditOptionGenerate] = useState({
  //   show: false,
  //   data: false,
  // });
  // const [showCreateOptionGeneral, setShowCreateOptionGenerate] = useState({
  //   show: false,
  //   data: false,
  // });

  // const [editImageProduct, setEditImageProduct] = useState({
  //   show: false,
  //   data: [],
  // });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const form = useWatch({ control });

  const getData = useCallback(() => {
    getCategories()
      .then((res) => {
        setCatalog({ items: res });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false)
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при загрузке"
        );
      });
  }, []);

  useLayoutEffect(() => {
    getData();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      await editCategory(data)
        .then(() => {
          NotificationManager.success("Каталог успешно обновлен");
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    []
  );

  // Регионы
  const onCreateRegion = useCallback(
    async (data) => {
      await createRegion({ ...data, id: form.id })
        .then(() => {
          NotificationManager.success("Регион успешно добавлен");
          getData();
          setShowCreateRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditRegion = useCallback(
    (data) => {
      editRegion(data)
        .then(() => {
          NotificationManager.success("Регион успешно обновлен");
          getData();
          setShowEditRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditRegionStatus = useCallback(
    (data) => {
      editRegionStatus(data)
        .then(() => {
          NotificationManager.success("Статус региона успешно обновлен");
          getData();
          setShowEditRegion({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onDeleteRegion = useCallback(
    (data) => {
      deleteRegion(data)
        .then(() => {
          NotificationManager.success("Регион успешно удален");
          getData();
          setShowDelete({ show: false, data: false, event: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при удалении"
          );
        });
    },
    [form]
  );

  // Сервер
  const onCreateServer = useCallback(
    ({ data, region }) => {
      createServer({ ...data, id: region.id })
        .then(() => {
          NotificationManager.success("Сервер успешно добавлен");
          getData();
          setShowCreateServer({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );

  const onEditServer = useCallback((data) => {
    editServer(data)
      .then(() => {
        NotificationManager.success("Сервер успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false, region: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditServerStatus = useCallback((data) => {
    editServerStatus(data)
      .then(() => {
        NotificationManager.success("Статус сервера успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteServer = useCallback((data) => {
    deleteServer(data)
      .then(() => {
        NotificationManager.success("Сервер успешно удален");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  // Параметры
  const onCreateParam = useCallback(
    (data) => {
      createParam({ ...data })
        .then(() => {
          NotificationManager.success("Параметр успешно добавлен");
          getData();
          setShowCreateParam({ show: false, data: false });
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error ?? "Ошибка при сохранении"
          );
        });
    },
    [form]
  );
  console.log(showCreateParam)
  const onEditParam = useCallback((data) => {
    editParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно обновлен");
        getData();
        setShowEditParam({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditParamStatus = useCallback((data) => {
    editParamStatus(data)
      .then(() => {
        NotificationManager.success("Статус параметра успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteParam = useCallback((data) => {
    deleteParam(data)
      .then(() => {
        NotificationManager.success("Параметр успешно удален");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  // Опции
  const onCreateOption = useCallback((data) => {
    createOption(data.data)
      .then(() => {
        NotificationManager.success("Опция успешно добавлен");
        getData();
        setShowCreateOption({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditOption = useCallback((data) => {
    editOption(data)
      .then(() => {
        NotificationManager.success("Опция успешно обновлена");
        getData();
        setShowEditOption({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onEditOptionStatus = useCallback((data) => {
    editOptionStatus(data)
      .then(() => {
        NotificationManager.success("Статус опции успешно обновлен");
        getData();
        setShowEditServer({ show: false, data: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const onDeleteOption = useCallback((data) => {
    deleteOption(data)
      .then(() => {
        NotificationManager.success("Опция успешно удалена");
        getData();
        setShowDelete({ show: false, data: false, event: false });
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при удалении"
        );
      });
  }, []);

  function renderTree(option) {
    return (
      <>
        <div
          key={option.id}
          className={
            "d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09"
          }
        >
          <a
            className={
              !option.parent
                ? "d-flex fs-09 fw-6 align-items-center"
                : "d-flex fs-09 text-muted align-items-center"
            }
            onClick={() => setShowEditOption({ show: true, data: option, categoryId: form.id })}
          >
            <span>{option.title}</span>
          </a>
          <div className="d-flex align-items-center">
            <Form.Check
              type="switch"
              id={`custom-switch-${option.id}`}
              checked={option.status}
              onChange={(e) =>
                onEditOptionStatus({
                  id: option.id,
                  status: e.target.checked,
                })
              }
            />
            <a
              className="ms-2 text-danger"
              onClick={() =>
                setShowDelete({
                  show: true,
                  data: option,
                  event: onDeleteOption,
                })
              }
            >
              <IoTrashOutline />
            </a>
          </div>
        </div>

        {option.children?.length > 0 &&
          option.children.sort((a, b) => a.priority - b.priority).map((child) => (
            <React.Fragment key={child.id}>
              <div className="ps-2 ms-2 border-left">
                {renderTree(child)}
                <div className="d-flex align-items-center justify-content-between ps-2 ms-2 border-left fs-09">
                  <a
                    className="my-2 fs-08 d-flex align-items-center"
                    onClick={() =>
                      setShowCreateOption({
                        show: true,
                        data: child,
                      })
                    }
                  >
                    <IoAddOutline /> Добавить
                  </a>
                </div>
              </div>
            </React.Fragment>
          ))}
      </>
    );
  }
  if (loading) {
    return <Loader full />;
  }

  if (!form) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой игры нет</h3>
      </Info>
    );
  }
  console.log(options.filter(e => e.categoryId.includes(showCreateParam.data.categoryId)))
  return (
    <Container fluid={true}>
      <Meta title="Редактировать параметры" />
      <section className="box">
        <Row>
          {/* <Col md={4} className="position-relative">
                <div className="position-sticky top-1">
                  <DragDropFile
                    file={data.media}
                    onChange={(e) =>
                      setEditImageProduct((info) => ({
                        show: !info.show,
                        data: e,
                      }))
                    }
                  />
                </div>
              </Col> */}
          {catalog.items && catalog.items.length > 0 && catalog.items.map(item =>
            <Col lg={12} xl={6}>
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h3>
                    {item.title}{" "}
                    <Badge bg="secondary" className="fs-07">
                      {item?.params?.length ?? 0}
                    </Badge>
                  </h3>
                  <Button
                    onClick={() => setShowCreateParam({ show: true, data: { categoryId: item.id, priority: item?.params && item.params.length + 1 } })}
                    className="btn-primary-outline"
                  >
                    <IoAdd />
                  </Button>
                </Card.Header>
                <Card.Body className="py-0 pb-3">
                  {item?.params && item.params.length > 0 &&
                    [...item.params].sort((a, b) => a.priority - b.priority).map((e, index) => {
                      index++;
                      return (
                        <div className="mb-4">
                          <div className="mb-2 d-flex align-items-center justify-content-between">
                            <a
                              className="d-flex align-items-center"
                              onClick={() =>
                                setShowEditParam({
                                  show: true,
                                  data: e,
                                })
                              }
                            >
                              <MdOutlineEdit className="text-muted me-1" />
                              <span className="fw-7">{e.title}</span>
                            </a>
                            <div className="d-flex align-items-center">
                              <Form.Check
                                className="me-2"
                                type="switch"
                                id="custom-switch-param"
                                defaultChecked={e.status}
                                onChange={(e2) =>
                                  onEditParamStatus({
                                    id: e.id,
                                    status: e2.target.checked,
                                  })
                                }
                              />

                              <Button
                                onClick={() =>
                                  setShowDelete({
                                    show: true,
                                    data: e,
                                    event: onDeleteParam,
                                  })
                                }
                                className="btn-danger-outline btn-xs"
                              >
                                <IoTrashOutline />
                              </Button>
                            </div>
                          </div>

                        </div>
                      );
                    })}
                </Card.Body>
              </Card>
            </Col>

          )}

        </Row>


        <CustomModal
          title="Новый параметр"
          show={showCreateParam.show}
          setShow={(e) => setShowCreateParam({ show: e, data: e })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowCreateParam({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onCreateParam(showCreateParam.data)}>
                Сохранить
              </Button>
            </>
          }
        >

          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              type="number"
              label="Цена"
              name="price"
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, price: e },
                }))
              }
            />
          </div>
          {options.filter(e => e.categoryId.includes(showCreateParam.data.categoryId)) && options.filter(e => e.categoryId.includes(showCreateParam.data.categoryId)).length > 0 && options.filter(e => e.categoryId.includes(showCreateParam.data.categoryId)).map(item => (
            < div className="mb-3" >
              <Input
                label={item.title}
                name={item.name}
                onChange={(e) =>
                  setShowCreateParam((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data, data:
                        { ...prev.data.data, [item.name]: e }
                    },
                  }))
                }
              />
            </div>
          ))}
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              placeholder={showCreateParam.data.priority ?? 0}
              onChange={(e) =>
                setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Col md={12}>
            <DragDropFile
              file={showCreateParam?.data?.media}
              onChange={(e) =>
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }))
              }
            />
            <ImageCropper
              file={editImageBanner.data[0]}
              show={editImageBanner.show}
              aspect={10 / 10}
              setShow={(e) =>
                setEditImageBanner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, file: e.file },
                }));
                e && setShowCreateParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, media: e.blob },
                }));
              }}
            />
          </Col>

        </CustomModal>

        <CustomModal
          title="Редактирование параметра"
          show={showEditParam.show}
          setShow={(e) => setShowEditParam({ show: e, data: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() => setShowEditParam({ show: false, data: false })}
              >
                Отмена
              </Button>
              <Button onClick={() => onEditParam(showEditParam.data)}>
                Сохранить
              </Button>
            </>
          }
        >

          <div className="mb-3">
            <Input
              label="Название"
              name="title"
              defaultValue={showEditParam.data.title}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, title: e },
                }))
              }
            />
          </div>
          <div className="mb-3">
            <Input
              label="Цена"
              name="price"
              defaultValue={showEditParam.data.price}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, price: e },
                }))
              }
            />
          </div>
          {options.filter(e => e.categoryId.includes(showEditParam.data.categoryId)) && options.filter(e => e.categoryId.includes(showEditParam.data.categoryId)).length > 0 && options.filter(e => e.categoryId.includes(showEditParam.data.categoryId)).map(item => (
            <div className="mb-3">
              <Input
                label={item.title}
                name={item.name}
                defaultValue={showEditParam?.data?.data && showEditParam?.data?.data[item.name]}
                onChange={(e) =>
                  setShowEditParam((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data, data:
                        { ...prev.data.data, [item.name]: e }
                    },
                  }))
                }
              />
            </div>
          ))}
          <div className="mb-3">
            <Input
              label="Порядок"
              name="priority"
              defaultValue={showEditParam.data.priority}
              onChange={(e) =>
                setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, priority: e },
                }))
              }
            />
          </div>
          <Form.Check
            label="Активен\Отключен"
            id="status-param-edit"
            className="mb-2"
            type="switch"
            defaultChecked={showEditParam.data.status}
            onChange={(e) =>
              setShowEditParam((prev) => ({
                ...prev,
                data: { ...prev.data, status: e.target.checked },
              }))
            }
          />
          <Col md={12}>
            <DragDropFile
              file={showEditParam?.data?.image ?? getImageURL({ path: showEditParam?.data?.media, type: "param" })}
              onChange={(e) =>
                setEditImageBanner((info) => ({
                  show: !info.show,
                  data: e,
                }))
              }
            />
            <ImageCropper
              file={editImageBanner.data[0]}
              show={editImageBanner.show}
              aspect={10 / 10}
              setShow={(e) =>
                setEditImageBanner((info) => ({ ...info, show: e }))
              }
              onComplete={(e) => {
                e && setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, file: e.file },
                }));
                e && setShowEditParam((prev) => ({
                  ...prev,
                  data: { ...prev.data, image: e.blob },
                }));
              }}
            />
          </Col>
        </CustomModal>



        <CustomModal
          title="Подтвердите удаление"
          show={showDelete.show}
          setShow={(e) => setShowDelete({ show: e, data: false, event: false })}
          footer={
            <>
              <Button
                className="btn-light me-3"
                onClick={() =>
                  setShowDelete({ show: false, data: false, event: false })
                }
              >
                Отмена
              </Button>
              <Button
                className="btn-danger"
                onClick={() =>
                  showDelete.event && showDelete.event(showDelete.data)
                }
              >
                Удалить
              </Button>
            </>
          }
        >
          Вы точно хотите удалить данный элемент? Восстановить данные будет не
          возможно.
        </CustomModal>
      </section>
    </Container >
  );
};

export default EditCategory;
