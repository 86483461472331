import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import Meta from "../../components/Meta";
import Loader from "../../components/UI/Loader";
import { useForm, useWatch } from "react-hook-form";
import Select from "../../components/UI/Select";
import { getCategories, getCategory } from "../../services/category";
import { getImageURL } from "../../helpers/image";
import Input from "../../components/UI/Input";
import Partition from "../../components/Partition";




const EditCategory = () => {
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState({ items: [], loading: true });
  const [partitionParams, setPartitionParams] = useState({
    partitionWidth: 500, // Ширина области визуализации (фиксированная)
    partitionHeight: 400, // Высота области визуализации (фиксированная)
    numDoors: 3,          // Количество дверей
    doorWidth: 80,        // Ширина одной двери
    doorHeight: 180,      // Высота одной двери
    handlePosition: 'left', // Положение ручки ('left', 'center', 'right')
    numHandles: 1,        // Количество ручек на двери
  });
  const doorWidth = partitionParams.partitionWidth / partitionParams.numDoors;
  const doorHeight = partitionParams.partitionHeight;

  const updatedParams = { ...partitionParams, doorWidth, doorHeight };
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const getData = useCallback(() => {
    getCategories()
      .then((res) => {
        setCatalog((prev) => ({
          ...prev,
          items: res,
          loading: false
        }));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false)
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при загрузке"
        );
      });
  }, []);

  useEffect(() => {
    getData();
  }, []);
  console.log(data)
  if (loading) {
    return <Loader full />;
  }
  return (
    <Container fluid={true}>
      <Meta title="Конфигуратор" />
      <section className="box">
        <Card className="mt-3 mb-4 config" >
          <img src="/images/products/prod1.jpg" alt="" />
          <Partition params={updatedParams} />

        </Card>
        <Row className='g-2 g-xl-3'>
          {catalog.items && catalog.items.length > 0 && catalog.items.map((item, i) => <>
            <Col md={3}>
              <Select
                value={data.param && data.param[i]}
                title="Выбрать"
                label={item.title}
                onClick={(e) => { setValue(`param.${i}`, e.value) }}
                data={item.params.map((e) => ({ value: e.id, title: e.title }))}
              />
            </Col>
            <Col md={3} className="mini">
              <img src={data.param && data.param[i] && getImageURL({ path: item.params.find(e => e.id == data.param[i])?.media, type: "param" })} alt="" />
            </Col>
          </>)}
          <Row>
            <Col md={3}>
              <p>Размер перегородки</p>
              <div className="d-flex mt-3">
                <Input
                  label="Ширина"
                  name="width"
                  register={register}
                  placeholder="0"
                />
                <Input
                  className="ms-3"
                  label="Высота"
                  name="height"
                  register={register}
                  placeholder="0"
                />
              </div>
            </Col>
          </Row>
          <p>Расположение ручек</p>
          <Form className="d-flex juxtify-content-between">
            <p>Дверь 1</p>
            <Form.Check
              label=" Слева"
              type="checkbox"
              value="left"
              checked={data.door1 && data.door1 == "left"}
              onChange={(e) => setValue('door1', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="right"
              checked={data.door1 && data.door1 == "right"}
              onChange={(e) => setValue('door1', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="without"
              checked={data.door1 && data.door1 == "without"}
              onChange={(e) => setValue('door1', e.target.value)}
            />
          </ Form>
          <Form className="d-flex juxtify-content-between">
            <p>Дверь 2</p>
            <Form.Check
              label=" Слева"
              type="checkbox"
              value="left"
              checked={data.door2 && data.door2 == "left"}
              onChange={(e) => setValue('door2', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="right"
              checked={data.door2 && data.door2 == "right"}
              onChange={(e) => setValue('door2', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="without"
              checked={data.door2 && data.door2 == "without"}
              onChange={(e) => setValue('door2', e.target.value)}
            />
          </ Form>
          <Form className="d-flex juxtify-content-between">
            <p>Дверь 3</p>
            <Form.Check
              label=" Слева"
              type="checkbox"
              value="left"
              checked={data.door3 && data.door3 == "left"}
              onChange={(e) => setValue('door3', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="right"
              checked={data.door3 && data.door3 == "right"}
              onChange={(e) => setValue('door3', e.target.value)}
            />
            <Form.Check
              label="Справа"
              type="checkbox"
              value="without"
              checked={data.door3 && data.door3 == "without"}
              onChange={(e) => setValue('door3', e.target.value)}
            />
          </ Form>

        </Row>
        <Button className="mt-3" onClick={() => onCreateParam(showCreateParam.data)}>
          Сохранить
        </Button>
      </section>
    </Container >
  );
};

export default EditCategory;
