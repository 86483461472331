import moment from "moment";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Card, Col, Row, Container, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChatboxOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { roles } from "../../helpers/member";
import { editMember, getMember } from "../../services/member";
import { getTasks } from "../../services/task";
import { titles } from "../../helpers/titles";

const MemberEdit = () => {
  const { memberId } = useParams();
  // const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  useLayoutEffect(() => {
    getMember({ id: memberId })
      .then((res) => {
        res.member.company = res?.member?.company?.title
        reset(res?.member);
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (event.target.checked) {
      // Добавляем значение в массив, если чекбокс отмечен
      setValue("taskTitle", [...(form?.taskTitle || []), value]);
    } else {
      // Удаляем значение из массива, если чекбокс снят
      setValue("taskTitle", (form?.taskTitle || []).filter((item) => item !== value));
    }
  };


  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="fw-7">Задачи</h5>
      </div>
    );
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сотруднике</h3>
      </Info>
    );
  }

  return (
    <Container fluid={true}>
      <Meta title="Редактирование сотрудника" />
      <section className="box">
        <h3 className="mb-4">Редактирование сотрудника</h3>

        <Card className="mb-4" body>
          <Row className="mb-3">
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Email"
                  name="email"
                  placeholder="Введите email"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Номер телефона"
                  name="phone"
                  placeholder="+7(000)000-00-00"
                  mask="+7(999)999-99-99"
                  value={form?.phone ?? ""}
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  type="date"
                  label="День рождения"
                  placeholder="Введите день рождения (Необязательно)"
                  name="brithday"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Фамилия"
                  name="lastName"
                  placeholder="Введите фамилию (Необязательно)"
                  errors={errors}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Имя"
                  name="firstName"
                  errors={errors}
                  register={register}
                  placeholder="Введите имя"
                  validation={{
                    required: "Введите имя",
                    maxLength: {
                      value: 20,
                      message: "Максимально 20 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Отчество"
                  name="patronymic"
                  errors={errors}
                  register={register}
                  placeholder="Введите отчество (Необязательно)"
                  validation={{
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Input
                  label="Организация"
                  name="company"
                  errors={errors}
                  register={register}
                  placeholder="Введите организацию"
                  validation={{
                    required: "Введите организацию",
                    maxLength: {
                      value: 50,
                      message: "Максимально 50 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Роль"
                onClick={(e) => setValue("role", e.value)}
                value={form.role}
                data={roles.slice(0, 2)}
              />
            </Col>
            <Col md={4}>
              <Select
                className="mb-4"
                label="Пол"
                onClick={(e) => setValue("sex", e.value)}
                value={form.sex ?? ""}
                data={[
                  { title: "Не указано", value: "" },
                  { title: "Мужской", value: "man" },
                  { title: "Женский", value: "woman" },
                ]}
              />
            </Col>
          </Row>
          <h5 className="mb-4">Изменить пароль</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Пароль"
                  name="password"
                  errors={errors}
                  placeholder="Введите пароль"
                  register={register}
                  validation={{
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Повторный пароль"
                  name="confirmPassword"
                  errors={errors}
                  placeholder="Введите повторно пароль"
                  register={register}
                  validation={{
                    maxLength: {
                      value: 200,
                      message: "Максимально 200 символов",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              isValid={isValid}
              isLoading={btnLoading}
              disabled={btnLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </div>
        </Card>

      </section>
    </Container>
  );
};

export default MemberEdit;
