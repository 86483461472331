import React, { useState, useLayoutEffect } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Home from "./Home";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import { IoArrowForwardCircle } from "react-icons/io5";
import { getStatistics } from "../services/statistic";

const Admin = () => {
  const auth = useSelector((state) => state?.auth);

  if (!auth.isAuth) {
    return <Home />;
  }

  const [statistic, setStatistic] = useState({
    users: 0,
    products: 0,
    categories: 0,
    orders: 0,
    total: 0,
  });

  useLayoutEffect(() => {
    getStatistics({
      user: true,
      product: true,
      order: true,
      category: true,
    }).then((res) => setStatistic(res));
  }, []);

  return (
    <>
      <Meta title="Главная" />
      <Container fluid={true}>
        <section className="mb-4">
          <Row className="mb-3">
            <Col md={3}>
              <Link
                to="/users"
                className="analytics flex-column align-items-center justify-content-center ads mb-3"
              >
                <img
                  src="/images/analitics/analitics-ads.svg"
                  height={65}
                  className="mb-4"
                />
                <h4 className="mb-1">{statistic.users ?? 0}</h4>
                <span>Пользователи</span>
              </Link>
            </Col>
            <Col md={3}>
              <Link
                to="/deals"
                className="analytics flex-column align-items-center justify-content-center tikets mb-3"
              >
                <img
                  src="/images/analitics/analitics-tickets.svg"
                  height={65}
                  className="mb-4"
                />
                <h4 className="mb-1">{statistic.orders ?? 0}</h4>
                <span>Рассчеты</span>
              </Link>
            </Col>
            <Col md={3}>
              <Link
                to="/deals"
                className="analytics flex-column align-items-center justify-content-center pay mb-3"
              >
                <img
                  src="/images/analitics/analitics-pay.svg"
                  height={65}
                  className="mb-4"
                />
                <h4 className="mb-1">{statistic.total ?? 0}</h4>
                <span>Сумма</span>
              </Link>
            </Col>
          </Row>
        </section>

        {/* <section className="box">
          <Row>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Статистика по задачам</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Топ админов</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
            <Col md={4}>
              <Card body>
                <h5 className="mb-3 fw-6 h6">Топ пользователей</h5>
                <div className="d-flex text-muted fs-09">Пока ничего нет</div>
              </Card>
            </Col>
          </Row>
        </section> */}
      </Container>
    </>
  );
};

export default Admin;
