const options = [
  {
    value: 1,
    title: "Увеличение размера",
    name: "plusMax",
    categoryId: [6],
  },
  {
    value: 2,
    title: "Количество дверей",
    name: "doorCount",
    categoryId: [5],
  },
  {
    value: 3,
    title: "Ширина по умолчанию",
    name: "dafaultWidth",
    categoryId: [5],
  },
  {
    value: 4,
    title: "Высота по умолчанию",
    name: "defaultHeigth",
    categoryId: [5],
  },
  {
    value: 5,
    title: "Увеличение профиля",
    name: "plusProf",
    categoryId: [5],
  },
  {
    value: 6,
    title: "Количество направляющих",
    name: "lineCount",
    categoryId: [5],
  },
  {
    value: 7,
    title: "Цвет",
    name: "color",
    categoryId: [8, 2],
  },

  {
    value: 8,
    title: "Ограничение по высоте",
    name: "minHeigth",
    categoryId: [9],
  },
  {
    value: 9,
    title: "Ограничение по ширине",
    name: "minWidth",
    categoryId: [9],
  },
  {
    value: 10,
    title: "Количество на одну дверь",
    name: "rolCount",
    categoryId: [9],
  },
  {
    value: 11,
    title: "Цена на работу",
    name: "workPrice",
    categoryId: [1],
  },
  {
    value: 12,
    title: "Тип",
    name: "type",
    categoryId: [2, 4],
  },
  {
    value: 13,
    title: "Длина ручки",
    name: "heightHand",
    categoryId: [4],
  },

];

const isOption = ({ option }) => {
  let optionInfo = options.find((e) => e.value === option);
  if (!optionInfo) {
    return "Опция не найдена";
  }

  return { option: optionInfo };
};

export { options, isOption };
